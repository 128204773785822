import type { AppProps } from 'next/app';
import { Roboto_Mono } from 'next/font/google';
import React from 'react';
import { createUseStyles } from 'react-jss';

import './style.css';

const roboto = Roboto_Mono({
  subsets: ['cyrillic', 'latin'],
  style: 'normal',
  preload: true,
  fallback: ['SF Mono', 'Consolas'],
  adjustFontFallback: true,
  display: 'optional',
});

const useGlobalStyles = createUseStyles({
  '@global': {
    'html, body': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },

    '*': {
      boxSizing: 'border-box',
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  useGlobalStyles();

  React.useEffect(() => {
    // Rehydrate
    const serverSideStyles = document.getElementById('server-side-styles');
    serverSideStyles?.parentNode?.removeChild(serverSideStyles);
  }, []);

  // Global jsx использется тут, а не useGlobalStyles
  // потому, что оно криво кодируется и не правильно рисуется
  // на сервере
  return (
    <>
      <style jsx global>
        {`
          html,
          body {
            font-family: ${roboto.style.fontFamily};
          }
        `}
      </style>

      <Component {...pageProps} />
    </>
  );
}
